import React from 'react'
import Layout from '../components/layout'
import styles from "../styles/prices.module.css"

const PlansPage = () => (
  <Layout className="prices">
    <div className="container">
      <h2>Решения, созданные с учетом ваших потребностей</h2>
      <div className={styles.wrap}>
        <div className={styles.box}>
          <h3>Базовый</h3>
          <ul className={styles.features}>
            <li>Расчет вознаграждений по готовым схемам</li>
            <li>Загрузка данных о продажах и активностях</li>
            <li>Ручная корректировка бонусов</li>
            <li>Одна активная бонусная схема расчета, любой вариант B2B, B2C</li>
            <li>Выплата вознаграждений через сайт</li>
            <li>Каталог нематериальных вознаграждений</li>
            <li>Отчеты</li>
          </ul>
        </div>
        <div className={styles.box}>
          <h3>Расширенный</h3>
          <ul className={styles.features}>
            <li>Расчет вознаграждений по готовым схемам</li>
            <li>Загрузка данных о продажах и активностях</li>
            <li>Ручная корректировка бонусов</li>
            <li>Выплата вознаграждений через сайт</li>
            <li>Одна активная бонусная схема расчета, любой вариант B2B, B2C, HR</li>
            <li>Изменяемый каталог нематериальных вознаграждений с возможностью покупки за бонусы</li>
            <li>Жизненный цикл бонусов и сгорание</li>
            <li>Плавающая бонусная ставка с зависимостью от дня недели, времени суток и тд. </li>
            <li>Доступ к API для внешних интеграций</li>
            <li>Интеграция с типовыми конфигурациями 1С</li>
            <li>sms и email уведомления об операция по бонусному счету</li>
            <li>Аналитические отчеты</li>
          </ul>
        </div>
        <div className={styles.box}>
          <h3>Профессиональный</h3>
          <ul className={styles.features}>
            <li>Расчет вознаграждений по готовым схемам</li>
            <li>Загрузка данных о продажах и активностях</li>
            <li>Ручная корректировка бонусов</li>
            <li>Выплата вознаграждений через сайт</li>
            <li>Любое число видов активных бонусных программ  B2B, B2C, HR, MLM</li>
            <li>Изменяемый каталог нематериальных вознаграждений с возможностью покупки за бонусы</li>
            <li>Жизненный цикл бонусов, их сгорание</li>
            <li>Плавающая бонусная ставка с зависимостью от дня недели, времени суток и тд. </li>
            <li>Доступ к API для внешних интеграций</li>
            <li>Интеграции с типовыми конфигурациями 1С и R-Keeper</li>
            <li>sms и email уведомления об операция по бонусному счету</li>
            <li>Аналитические отчеты</li>
            <li>Детализация бонусных программ по филиалам компании</li>
            <li>Виджеты с личным кабинетом для участников программ лояльности</li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
)

export default PlansPage
